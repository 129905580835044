import React, { useState, useEffect } from "react";
import ReactApexChart from "react-apexcharts";
import { useColorMode } from "@chakra-ui/react";
import theme from "../theme/theme";
import moment from "moment";

const LineChart = (props) => {
  const [chartData, setChartData] = useState(props.chartData || []);
  const [chartOptions, setChartOptions] = useState(props.chartOptions || {});
  const [timestamps, setTimestamps] = useState(props.timestamps || []);

  //console.log(props)

  // Replace componentDidUpdate with useEffect
  useEffect(() => {
    setChartData(props.chartData);
    setChartOptions(props.chartOptions);
    setTimestamps(props.timestamps);
    console.log("Props: ",chartData,chartOptions,timestamps)
  }, [props.chartData, props.chartOptions, timestamps]);

  const { colorMode } = useColorMode();

  // Directly assign colors based on color mode
  const chartColors =
    colorMode === "dark"
      ? theme.colors.chartColors.dark
      : theme.colors.chartColors.light;
  // const textColor =  colorMode === 'dark' ? chartColors[4] : chartColors[0]
  const textColor = colorMode === "dark" ? "#FFF" : "#000";

  // Create a new configuration object
  const updatedChartOptions = {
    ...chartOptions,
    chart: {
      id: "basic-line-chart",
      type: "line",
      toolbar: {
        show: false,
      },
      dataLabels: {
        enabled: true,
      },
      markers: {
        size: 1,
        colors: "white",
        strokeColors: "#7551FF",
        strokeWidth: 3,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        showNullDataPoints: true,
      },
      dropShadow: {
        enabled: true,
        top: 13,
        left: 0,
        blur: 10,
        opacity: 0.1,
        color: "#4318FF",
      },
    },
    colors: chartColors,
    stroke: {
      curve: "smooth",
      type: "line",
    },
    legend: {
      show: true,
    },
    grid: {
      show: false,
      column: {
        color: ["#7551FF", "#39B8FF"],
        opacity: 0.5,
      },
    },
    xaxis: {
      type: "datetime",
      categories: timestamps.map(ts => moment(ts).utc().format("MMM DD")),
      labels: {
        style: {
          colors: textColor,
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      title: {
        style: {
          color: textColor,
        },
        title: {
          text: chartOptions?.chartTitle || "chart-title",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: textColor,
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      title: {
        style: {
          color: textColor,
        },
        title: {
          text: chartOptions?.yAxisTitle || "y-axis-title",
        },
      },
    },
    markers: {
      size: 4,
    },
    tooltip: {
      theme: colorMode,
      enabled: true,
    },
    // other options as needed
  };

  return (
    chartData &&
    timestamps.length > 0 && (
      <ReactApexChart
        options={updatedChartOptions}
        series={chartData}
        type="line"
        width="100%"
        height="100%"
      />
    )
  );
};

export default LineChart;
