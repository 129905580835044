import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Box,
  Flex,
  Text,
  Button,
  Input,
  FormControl,
  FormLabel,
  Image,
  Center,
  Icon,
  Link,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";
import { ExternalLinkIcon, LinkIcon } from "@chakra-ui/icons";
import { css, keyframes } from "@emotion/react";
import "./App.css";
import { FloatingBall } from "./components/FloatingBall";
import { px } from "framer-motion";
import FullScreenModal from "./components/FullScreenModal";
import TotalNodes from "./components/TotalNodes";
import Lottie from "lottie-react";
import shapesAnimation from "./components/shapes.json";
import botttomVector from "./bottomVector.svg";

const scroll = keyframes`
    0% { transform: translateX(0); }
    100% { transform: translateX(-50%); } 
  `;

const scrollingAnimation = css`
  animation: ${scroll} 20s linear infinite; /* Adjust time for different speeds */
`;
const calculateTimeLeft = (interval) => {
  let endDate = new Date();
  if (interval === "guess") {
    endDate = new Date("2024-02-26T00:00:00");
  }
  if (interval === "prize") {
    endDate = new Date("2024-03-01T00:00:00");
  }

  const now = new Date();
  const difference = endDate - now;

  let timeLeft = null;

  if (difference > 0) {
    timeLeft = {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  }

  return timeLeft;
};

function App() {
  const [nodeCount, setNodeCount] = useState(0);
  // const [entryCount, setEntryCount] = useState("");
  const [timeLeftToGuess, setTimeLeftToGuess] = useState(
    calculateTimeLeft("guess")
  );
  const [timeLeftToPrize, setTimeLeftToPrize] = useState(
    calculateTimeLeft("prize")
  );
  const { colorMode, toggleColorMode } = useColorMode();

  useEffect(() => {
    const timer = setTimeout(() => {
      if (timeLeftToGuess) {
        setTimeLeftToGuess(calculateTimeLeft("guess"));
      } else {
        setTimeLeftToPrize(calculateTimeLeft("prize"));
      }
    }, 1000);

    return () => clearTimeout(timer);
  });

  // Set the color mode dark since the config isn't doing it right!
  useEffect(() => {
    if (colorMode === "light") {
      toggleColorMode("dark");
    }
  }, []);

  const componentBg = useColorModeValue("white", "#171753");
  const formattedtimeLeftToGuess = timeLeftToGuess
    ? `${timeLeftToGuess?.days}:${timeLeftToGuess.hours}:${timeLeftToGuess.minutes}:${timeLeftToGuess.seconds}`
    : `00:00:00:00`;
  const formattedtimeLeftToPrize = timeLeftToPrize
    ? `${timeLeftToPrize?.days}:${timeLeftToPrize.hours}:${timeLeftToPrize.minutes}:${timeLeftToPrize.seconds}`
    : `00:00:00:00`;
  const [errorStatus, setErrorStatus] = useState("");
  const [successStatus, setSuccessStatus] = useState("");
  const [showsAllOS, setShowAllOS] = useState(false);

  useEffect(() => {
    const api =
      "https://faucet-api.koii.network/api/leaderboard/get-nodes-summary";
    const fetchData = async () => {
      try {
        const response = await axios.get(api);
        setNodeCount(response.data.totalNumberOfActiveStakingKeys);
      } catch (error) {}
    };

    fetchData();
  }, []);

  // useEffect(() => {
  //   fetchEntryCount();
  // }, []);

  // const fetchEntryCount = async () => {
  //   const url = "http://localhost:3000/api/entrycount";

  //   try {
  //     const response = await axios.get(url);
  //     console.log("Entry count:", response.data);
  //     setEntryCount(response.data);
  //   } catch (error) {
  //     console.error("Error fetching item count:", error);
  //   }
  // };

  function submitEstimateRequest() {
    var userEmail = document.getElementById("userEmail").value;
    var nodeEstimate = document.getElementById("nodeEstimate").value;

    // Validate node estimate as a number
    if (isNaN(nodeEstimate) || nodeEstimate < 10000) {
      setErrorStatus("Invalid node estimate");
      return;
    }

    // Validate email format
    var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(userEmail)) {
      setErrorStatus("Invalid email address");
      return;
    }

    // Create the request body
    var requestBody = {
      userEmail: userEmail,
      nodeEstimate: nodeEstimate,
    };

    // Set up the request
    fetch(`${process.env.REACT_APP_GUESS_API}/api/estimateRequest`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.error) {
          setErrorStatus(data.message);
        } else {
          setSuccessStatus(data.message);
          setErrorStatus("");
          // fetchEntryCount();
        }
      })
      .catch((error) => {
        alert("Failed to submit estimate request.");
      });
  }

  return (
    <>
      {colorMode === "dark" && (
        <style>
          {/* To make calendar icons change color */}
          {`
          input[type="date"]::-webkit-calendar-picker-indicator {
            filter: invert(52%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
          }
        `}
        </style>
      )}

      <Flex direction="column" bg="koiipurpledark" color="white" minH="screen">
        {/* Top Navigation Bar */}
        <Flex
          px={{ base: "4", md: "8" }}
          py="3"
          align="center"
          justify="space-between"
          boxShadow={"md"}
          style={{ backgroundColor: "#171753" }} // Changed bg color using inline style
        >
          {/* Logo on the Left */}
          <Image
            src="/logosvg.svg" // Update the path to your logo
            alt="Koii Logo"
            htmlWidth="120px" // Adjust size as needed
            htmlHeight="auto"
          />

          {/* Join the Network Button on the Right */}
          <Link
            href="https://koii.network/node?&utm_campaign=node&utm_medium=EthDenver&utm_source=minigame"
            isExternal
          >
            <Button
              colorScheme="#171753" // Chakra UI does not support hex color codes in colorScheme, use a color from the theme
              color="white" // The text color
              size="md"
              borderRadius="full" // This will give it fully rounded corners
              _hover={{ bg: "#BEF0ED" }} // The color when the mouse hovers over the button
              border="2px" // The width of the border
              borderColor="#9BE7C4" // The border color
            >
              Join the Network
            </Button>
          </Link>
        </Flex>

        {/* Title and Subtitle Section */}
        <Box
          bg="koiipurpledark"
          py={{ base: "3", md: "1" }}
          textAlign="center"
          mt="4%"
        >
          <Text fontSize="6xl" fontWeight="bold" color="#BEF0ED">
            Guess the Number{" "}
          </Text>
          <Text>of Koii Nodes active on March 1st</Text>
          <Text
            fontSize={{ base: "lg", md: "3xl" }}
            fontWeight="semibold"
            color="#FFEE81"
          >
            and win 5,000 KOII
          </Text>
        </Box>

        {/* Node Count Section */}

        <Flex
          align="center"
          justify="space-between"
          bg="koiipurpledark"
          py={{ md: "12" }}
          style={{ position: "relative", width: "100%", zIndex: 5 }}
        >
          {/* Left Animation */}

          <Box width={{ base: "0%", md: "25%" }} style={{ zIndex: 1 }}>
            <Lottie animationData={shapesAnimation} />
          </Box>

          <TotalNodes
            width={{ base: "80%", md: "50%", xl: "40%" }}
            bgColor={componentBg}
            maxHeight={"95dvh"}
          />

          {/* Right Animation */}
          <Box width={{ base: "0%", md: "25%" }} style={{ zIndex: 1 }}>
            <Lottie animationData={shapesAnimation} />
          </Box>
        </Flex>

        {/* Node Count and Input Section */}
        <Center
          bg="koiipurpledark"
          py={{ base: "6", md: "6" }}
          flexDirection="column"
        >
          {timeLeftToGuess ? (
            <>
              {/* Node Count Display */}
              <Text fontSize="3xl" fontWeight="semibold" color="#BEF0ED">
                Current Nodes
              </Text>
              <Text fontSize="6xl" fontWeight="semibold" pb="8" color="#FFEE81">
                {nodeCount}
              </Text>

              {/* Input and Button */}
              <Flex
                direction="column"
                align="center"
                justify="center"
                width="full"
                px={{ base: "4", md: "8", mb: "4" }}
              >
                {/* Node Estimate Input at the top */}

                <Box
                  textAlign="center"
                  minW="300px" // Minimum width of the input box
                  maxW="xl" // Maximum width of the input box
                  mb={4} // Margin bottom to separate from other elements
                  mx="auto" // Center the box horizontally
                >
                  <FormControl id="estimateForm" display="inline-block">
                    <FormLabel
                      htmlFor="nodeEstimate"
                      fontWeight="bold"
                      textAlign="center"
                      color="#BEF0ED" // Use Chakra UI color scheme instead of hex for consistency
                    >
                      MAKE YOUR GUESS
                    </FormLabel>
                    <Input
                      id="nodeEstimate"
                      mt={4} // Add top margin to space out from the label
                      mb={2} // Add bottom margin to space out from the next input
                      name="nodeEstimate"
                      backgroundColor={"white"}
                      textColor={"black"}
                      placeholder="Your Guess"
                      focusBorderColor="blue.300"
                      width={{ base: "80%", md: "90%" }}
                      textAlign="center"
                      borderColor="gray.300" // Lighter border color for better visibility
                      borderRadius="full"
                      _placeholder={{ color: "gray.500" }}
                      boxShadow="sm" // Use Chakra UI's predefined shadow for consistency
                      size="lg"
                    />
                    <Input
                      id="userEmail"
                      backgroundColor={"white"}
                      mt={4} // Consistent margin with the first input
                      placeholder="Your Koii Node E-mail"
                      focusBorderColor="blue.300"
                      textAlign="center"
                      textColor={"black"}
                      borderColor="gray.300" // Consistent border color
                      borderRadius="full"
                      _placeholder={{ color: "gray.500" }}
                      width={{ base: "80%", md: "90%" }}
                      boxShadow="sm" // Consistent shadow
                      size="lg"
                    />
                  </FormControl>
                  <Button
                    borderRadius="full"
                    size="lg"
                    mt={4}
                    bg={"#BEF0ED"}
                    onClick={submitEstimateRequest}
                    width={{ base: "full", sm: "auto" }} // Full width on small screens, auto on larger screens
                    _hover={{
                      transform: "scale(1.2)",
                      transition: "transform 0.2s ease-in-out",
                    }}
                  >
                    <Text color={"#171753"}>Submit</Text>
                  </Button>
                  <Box>
                    {errorStatus && (
                      <span className="text-red-500 text-xs italic">
                        <Text color={"#FFC78F"}>{errorStatus}</Text>
                      </span>
                    )}
                    {successStatus && (
                      <Text fontWeight={"bold"} color={"lightgreen"}>
                        {" "}
                        {successStatus}
                      </Text>
                    )}
                  </Box>
                </Box>
              </Flex>
            </>
          ) : (
            <Flex>
              <Box
                textAlign="center"
                w={{ base: "90vw", md: "50vw" }}
                borderWidth="thick"
                borderColor="gray.200"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                mb={4}
                p={10}
                mx="auto"
                borderRadius="10px"
              >
                <Text
                  fontSize={{ base: "5xl", md: "6xl" }}
                  fontWeight="semibold"
                  mb={3}
                  color="#FFEE81"
                >
                  Time's up!
                </Text>
                {/* Node Count Display */}
                <Text fontSize="3xl" fontWeight="semibold" color="#BEF0ED">
                  Nodes at contest completion
                </Text>
                <Text
                  fontSize="6xl"
                  fontWeight="semibold"
                  pb="8"
                  color="#FFEE81"
                >
                  35134
                </Text>

                <Text fontSize="3xl" fontWeight="semibold" color="#BEF0ED">
                  Winner's guess
                </Text>
                <Text
                  fontSize="6xl"
                  fontWeight="semibold"
                  pb="8"
                  color="#FFEE81"
                >
                  35128
                </Text>

                <Text
                  fontSize={{ base: "2xl", md: "3xl" }}
                  fontWeight="semibold"
                  color="#BEF0ED"
                >
                  Current Nodes
                </Text>
                <Text
                  fontSize="6xl"
                  fontWeight="semibold"
                  pb="8"
                  color="#FFEE81"
                >
                  {nodeCount}
                </Text>
                <Text fontSize="l" fontWeight="semibold" color="#BEF0ED">
                  Contest completed on March 1st winner's Public Key was:
                </Text>
                <Text fontSize="l" fontWeight="semibold" color="white" maxWidth={"100%"}>
                  CxryvobXNCpAPVVASyNMsTQWwB4LtQH2MzAAVCCa18L8
                </Text>
              </Box>
            </Flex>
          )}
        </Center>
        <Box pb={6} textAlign="center">
          <Text mt={2}>1519 people made a guess!</Text>
        </Box>
        {/* <Box
          p={6}
          textAlign="center"
          border={"2px solid #BEF0ED"}
          mx={"auto"}
          mb={10}
          width={{ base: "80%", md: "30%" }}
          borderRadius="10"
        >
          <Text mt={2}>Follow us for a chance to win:</Text>

          <Text
            fontSize={{ base: "xl", md: "4xl" }}
            fontWeight="semibold"
            color="#FFEE81"
            textShadow={"1px 1px 1px white"}
          >
            10,000 KOII
          </Text>

          <Link
            href="https://twitter.com/KoiiFoundation"
            isExternal
            _hover={{ color: "#FFEE81" }}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Text _hover>Follow Koii on Twitter</Text>
            <Icon ml={1} mb={1} as={ExternalLinkIcon} />
          </Link>
        </Box> */}
        {/* {timeLeftToGuess ? (
          <Box
            p={6}
            textAlign="center"
            bg="#BEF0ED"
            color="#171753"
            m="auto"
            width={{ base: "90%", md: "55%" }}
            borderRadius={{
              base: "2rem 2rem 2rem 2rem",
              md: "25rem 2rem 25rem 2rem",
            }}
          >
            <Text fontSize={{ base: "lg", md: "2xl" }} fontWeight="bold" pb="4">
              Time left to enter your guess
            </Text>
            <Text fontSize="4xl" fontWeight="extrabold" pb="2" color="#171753">
              {formattedtimeLeftToGuess}
            </Text>
            <Text color={"#171753"}>
              Winner will be announced on March 1st 2024.
            </Text>
            <Link href="https://www.koii.network/node" isExternal>
              <Text fontWeight={"bold"} _hover={{ color: "darkblue" }}>
                Click here to download the Koii Node
              </Text>
            </Link>
          </Box>
        ) : (
          <>
            <Box
              p={6}
              textAlign="center"
              bg="#BEF0ED"
              color="#171753"
              m="auto"
              width={{ base: "90%", md: "55%" }}
              borderRadius={{
                base: "2rem 2rem 2rem 2rem",
                md: "25rem 2rem 25rem 2rem",
              }}
            >
              <Text
                fontSize={{ base: "lg", md: "2xl" }}
                fontWeight="bold"
                pb="4"
              >
                Time left until Prize
              </Text>
              <Text
                fontSize="4xl"
                fontWeight="extrabold"
                pb="2"
                color="#171753"
              >
                {formattedtimeLeftToPrize}
              </Text>
              <Text color={"#171753"}>
                Winner will be announced on March 1st 2024.
              </Text>
              <Link href="https://www.koii.network/node" isExternal>
                <Text fontWeight={"bold"} _hover={{ color: "darkblue" }}>
                  Click here to download the Koii Node
                </Text>
              </Link>
            </Box>
          </>
        )} */}

        <Box pt="10">
          <Image
            src={botttomVector}
            alt="Bottom Vector"
            htmlWidth="100%"
            htmlHeight="auto"
          />
        </Box>
      </Flex>
    </>
  );
}

export default App;
