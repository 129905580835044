import { mode } from "@chakra-ui/theme-tools";
const Card = {
  baseStyle: (props) => ({
    pt: "50px",       // Increased top padding to make space for full screen button
    pr: "20px",       // Right padding
    pb: "20px",       // Bottom padding
    pl: "20px",       // Left padding
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
    borderRadius: "20px",
    minWidth: "0px",
    wordWrap: "break-word",
    bg: mode("#ffffff", "navy.800")(props),
    backgroundClip: "border-box",
  }),
};

export const CardComponent = {
  components: {
    Card,
  },
};
