// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
  Input,
} from "@chakra-ui/react";
// Custom components
import Card from "./Card.js";
import LineChart from "./LineChart";
import React, { useState, useEffect } from "react";

import { IoCheckmarkCircle } from "react-icons/io5";
// Assets
import { RiArrowUpSFill } from "react-icons/ri";

//Fetch logic

async function fetchFlexibleNodeNumbers(start, end) {
  try {
    // Construct the URL with query parameters
    let url = `${process.env.REACT_APP_DASHBOARD_API}/flexibleNodeNumbers`;

    let queryParams = new URLSearchParams();
    if (start) queryParams.append("start", start);
    if (end) queryParams.append("end", end);
    url += `?${queryParams.toString()}`;

    // Make the fetch request
    const response = await fetch(url);

    // Check if the response is OK
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the response as JSON
    const data = await response.json();

    // Log the result to the console
    return data;
  } catch (error) {
    console.error("Error fetching data:", error.message);
  }
}

const fetchMostRecentNodes = async () => {
  const nodesSummary =
    "https://faucet-api.koii.network/api/leaderboard/get-nodes-summary";
  try {
    const response = await fetch(nodesSummary);
    // Check if the response is OK
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    // Parse the response as JSON
    const data = await response.json();

    // Log the result to the console
    return data.totalNumberOfActiveStakingKeys;
  } catch (error) {
    console.error("Error fetching data: ", error);
  }
};

// Passed through to LineChart
const preChartOptions = {
  fileName: "Total Nodes",
  chartTitle: "",
  xAxisTitle: "Days",
  yAxisTitle: "Nodes",
};

export default function TotalSpent(props) {
  // Inside your component
  const [startDate, setStartDate] = useState(
    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toISOString().split("T")[0] // 7 days previous without time
  );
  const [endDate, setEndDate] = useState(
    new Date().toISOString().split("T")[0] // current date without time
  );

  // States for nodes data
  const [nodes, setNodes] = useState([]);
  const [timestamps, setTimestamps] = useState([]);
  const [localChartData, setLocalChartData] = useState(false);
  const [localChartOptions, setLocalChartOptions] = useState(false);
  const [readyToRender, setReadyToRender] = useState(false);

  useEffect(() => {
    async function fetchData() {
      try {
        const fetchedNodesData = await fetchFlexibleNodeNumbers(
          startDate,
          endDate
        );

        if (fetchedNodesData) {
          setTimestamps(fetchedNodesData.map((data) => data.timestamp));
          const nodes = fetchedNodesData.map((data) => data.nodes);
          if (isSameDay(endDate)) {
            const mostRecentNodes = await fetchMostRecentNodes();
            nodes[nodes.length - 1] = mostRecentNodes;
          } else {
          }

          setNodes(nodes);
          let preChartData = [
            {
              name: "Node Count",
              data: nodes,
            },
          ];

          setLocalChartData(preChartData);

          setLocalChartOptions(preChartOptions);
        }

        setReadyToRender(true);
      } catch (error) {
        console.error("Error fetching data:", error.message);
      }
    }

    fetchData();
  }, [startDate, endDate]);

  const { ...rest } = props;

  function isSameDay(dateString) {
    const today = new Date().toISOString().split("T")[0];

    return today === dateString;
  }

  // Chakra Color Mode

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = useColorModeValue("secondaryGray.600", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const iconColor = useColorModeValue("brand.500", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );
  return (
    <Card
      justifyContent="center"
      align="center"
      direction="column"
      w="100%"
      h="100%"
      mb="0px"
      {...rest}
    >
      <Flex justify="space-between" ps="0px" pe="20px" pt="5px">
        <Flex align="center" w="100%" h="100%">
          <Text
            me="auto"
            color={textColor}
            fontSize="xl"
            fontWeight="700"
            lineHeight="100%"
          >
            Nodes
          </Text>

          <Flex flexDirection={{ base: "column", md: "row" }} w="80%">
            <Box flex="1" ml={{ base: "1", md: "0" }}>
              <Input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                min="2024-01-23"
                width={"100%"}
              />
            </Box>

            <Box flex="1" ml={{ base: "0", md: "2" }}>
              <Input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                min="2024-01-23"
                width={"100%"}
              />
            </Box>
          </Flex>
        </Flex>
      </Flex>
      <Flex w="100%" h="100%" flexDirection={{ base: "column", lg: "row" }}>
        <Flex flexDirection="column" me="20px" mt="28px">
          <Text
            color={textColor}
            fontSize="34px"
            textAlign="start"
            fontWeight="700"
            lineHeight="100%"
          >
            {nodes[nodes.length - 1]}
          </Text>
          <Flex align="center" mb="20px">
            <Text
              color="secondaryGray.600"
              fontSize="sm"
              fontWeight="500"
              mt="4px"
              me="12px"
            >
              New
            </Text>
            <Flex align="center">
              <Icon as={RiArrowUpSFill} color="green.500" me="2px" mt="2px" />
              <Text color="green.500" fontSize="sm" fontWeight="700">
                {nodes[nodes.length - 1] - nodes[0]}
              </Text>
            </Flex>
          </Flex>

          <Flex align="center">
            <Icon as={IoCheckmarkCircle} color="green.500" me="4px" />
            <Text color="green.500" fontSize="md" fontWeight="700">
              {((nodes[nodes.length - 1] / nodes[0]) * 100).toFixed(0) - 100}%
              Increase
            </Text>
          </Flex>
        </Flex>
        <Box minH="260px" minW="75%" mt="auto">
          {readyToRender && (
            <>
              <LineChart
                chartData={localChartData}
                chartOptions={localChartOptions}
                timestamps={timestamps}
              />
            </>
          )}
        </Box>
      </Flex>
    </Card>
  );
}
